/** @jsx jsx */
import { Link } from "gatsby";
import { jsx } from "theme-ui";
import { motion } from "framer-motion";

const transition = { duration: 1.4, ease: [0.6, 0.01, -0.05, 0.9] };

const animate = { y: 0, transition: { duration: 1, ...transition } };

const containerVariants = {
    enter: {
        x: 0,
        opacity: 1,
        transition: {
            staggerChildren: 0.2
        }
    }
    // exit: { x: -300, opacity: 0 },
};

const childVariants = {
    enter: {
        x: 0,
        opacity: 1,
        transition: {
            duration: 0.5,
            ...transition
        }
    },
    exit: {
        x: 200,
        opacity: 0
    }
};

const Menu = ({ isOpen }) => {
    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 0.5 } }}
            exit={{ opacity: 0, transition: { duration: 0.5 } }}
            sx={{
                width: "100%",
                height: "100%",
                position: "fixed",
                zIndex: 10,
                top: 0,
                left: 0,
                bg: "rgba(0,0,0,0.97)"
            }}
        >
            <div
                className="menu-links"
                sx={{
                    position: "absolute",
                    top: "50%",
                    transform: "translateY(-50%)",
                    right: 2,
                    textAlign: "right",
                    variant: "text.heading.sub"
                }}
            >
                <motion.div
                    variants={containerVariants}
                    initial="exit"
                    animate="enter"
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        color: "white",
                        listStyle: "none",
                        overflow: "hidden"
                    }}
                >
                    <motion.span variants={childVariants} sx={{ my: 1 }}>
                        <Link
                            cursor-scale="scale-up"
                            to="/projects"
                            sx={{
                                textDecoration: "none",
                                color: "white",
                                "::selection": { color: "black", bg: "white" }
                            }}
                        >
                            Projects
                        </Link>
                    </motion.span>
                    <motion.span variants={childVariants} sx={{ my: 1 }}>
                        <Link
                            cursor-scale="scale-up"
                            to="/skillset"
                            sx={{
                                textDecoration: "none",
                                color: "white",
                                "::selection": { color: "black", bg: "white" }
                            }}
                        >
                            Skillset
                        </Link>
                    </motion.span>
                    <motion.span variants={childVariants} sx={{ my: 1 }}>
                        <Link
                            cursor-scale="scale-up"
                            to="/about"
                            sx={{
                                textDecoration: "none",
                                color: "white",
                                "::selection": { color: "black", bg: "white" }
                            }}
                        >
                            About
                        </Link>
                    </motion.span>
                    {/* <motion.span variants={childVariants} sx={{ my: 1 }}>
                        <Link
                            cursor-scale="scale-up"
                            to="/"
                            sx={{
                                textDecoration: "none",
                                color: "white",
                                "::selection": { color: "black", bg: "white" }
                            }}
                        >
                            Contact
                        </Link>
                    </motion.span> */}
                </motion.div>
            </div>
            <motion.div
                initial={{ y: 100 }}
                animate={{ y: 0, transition: { delay: 1, ...transition } }}
                className="menu-footer"
                sx={{
                    position: "absolute",
                    display: "flex",
                    justifyContent: "space-between",
                    bottom: "5%",
                    right: 2,
                    left: 2,
                    variant: "text.body.main"
                }}
            >
                <p sx={{ color: "white", m: 0, "::selection": { color: "black", bg: "white" } }}>
                    Developer + more
                </p>
                <a
                    href="https://www.linkedin.com/in/matt-dunn-67172b208/"
                    cursor-scale="scale-up"
                    sx={{
                        color: "white",
                        textDecoration: "none",
                        "::selection": { color: "black", bg: "white" }
                    }}
                >
                    Linked
                    <span
                        sx={{
                            bg: "white",
                            borderRadius: "5px",
                            color: "black",
                            px: "3px",
                            ml: "2px",
                            "::selection": { variant: "selection" }
                        }}
                    >
                        in
                    </span>
                </a>
            </motion.div>
        </motion.div>
    );
};

export default Menu;
