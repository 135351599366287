/** @jsx jsx */
import { useState } from "react";
import { jsx } from "theme-ui";

import Cursor from "./cursor";
import Footer from "./footer";
import Header from "./header";

const Layout = ({ children }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <main>
            <Cursor isOpen={isOpen} />
            <Header isOpen={isOpen} setIsOpen={setIsOpen} />
            <div sx={{ bg: "white", pt: 7 }}>{children}</div>
            <Footer />
        </main>
    );
};

export default Layout;
