/** @jsx jsx */
import { jsx } from "theme-ui";
import { motion } from "framer-motion";
import { ExpandOutline } from "@emotion-icons/evaicons-outline/ExpandOutline";
import { useEffect, useRef, useState } from "react";

import { lerp, getMousePos, getSiblings } from "../lib/helpers";

const transition = { duration: 0.4, ease: [0.44, 0.17, 0.25, 0.9] };

const Cursor = ({ isOpen }) => {
    const cursorEl = useRef();
    const [scale, setScale] = useState(0);
    const [color, setColor] = useState("#dddddd");

    useEffect(() => {
        const cursor = cursorEl.current;

        let mouse = { x: 0, y: 0 };
        const cursorConfig = {
            x: { previous: 0, current: -100, amt: 0.15 },
            y: { previous: 0, current: -100, amt: 0.15 }
        };

        const onMouseMoveEv = e => {
            cursorConfig.x.previous = cursorConfig.x.current = mouse.x;
            cursorConfig.y.previous = cursorConfig.y.current = mouse.y;

            requestAnimationFrame(updateCursorPosition);

            window.removeEventListener("mousemove", onMouseMoveEv);
        };

        window.addEventListener("mousemove", e => (mouse = getMousePos(e)));
        window.addEventListener("mousemove", onMouseMoveEv);
        window.addEventListener("mousedown", () => setScale(0.1));
        window.addEventListener("mouseup", () => setScale(0));

        const updateCursorPosition = e => {
            cursorConfig.x.current = mouse.x;
            cursorConfig.y.current = mouse.y;

            for (const key in cursorConfig) {
                cursorConfig[key].previous = lerp(
                    cursorConfig[key].previous,
                    cursorConfig[key].current,
                    cursorConfig[key].amt
                );
            }

            cursor.style.transform = `translate(${cursorConfig.x.previous}px, ${cursorConfig.y.previous}px)`;

            requestAnimationFrame(updateCursorPosition);
        };

        /*
         * Cursor Effects
         */

        // Case 1 - Media Blending
        const mediaBlend = document.querySelectorAll("[cursor-type='media-blend']");
        mediaBlend.forEach(el => {
            el.addEventListener("mouseenter", () => {
                cursor.classList.add("media-blend");
            });
            el.addEventListener("mouseleave", () => {
                cursor.classList.remove("media-blend");
            });
        });

        //Case 2 - Inverse
        const inverse = document.querySelectorAll("[cursor-type='inverse']");
        inverse.forEach(el => {
            el.addEventListener("mouseenter", () => {
                setColor("#fff");
            });
            el.addEventListener("mouseleave", () => {
                setColor("#ddd");
            });
        });

        //Case 3 - Change Color with info
        const colorChange = document.querySelectorAll("[cursor-type='color-change']");
        colorChange.forEach(el => {
            el.addEventListener("mouseenter", () => {
                setColor(`${el.getAttribute("icon-color")}`);
                cursor.children[0].children[0].innerHTML = el.getAttribute("icon-name");
            });
            el.addEventListener("mouseleave", () => {
                cursor.children[0].children[0].innerHTML = "";
                setColor("#ddd");
            });
        });

        //Case 3 - Display Icon
        const icon = document.querySelectorAll("[cursor-type='icon']");
        icon.forEach(el => {
            el.addEventListener("mouseenter", () => {
                setColor("#ddddddb3");
                cursor.children[0].children[1].style.display = "block";
            });
            el.addEventListener("mouseleave", () => {
                setColor("#ddd");
                cursor.children[0].children[1].style.display = "none";
            });
        });

        /**
         * Cursor Scaling
         */

        //Scale Up
        const scaleUp = document.querySelectorAll("[cursor-scale='scale-up']");
        scaleUp.forEach(el => {
            el.addEventListener("mouseenter", () => {
                setScale(1.5);
            });
            el.addEventListener("mouseleave", () => {
                setScale(0);
            });
        });

        //Scale Down
        const scaleDown = document.querySelectorAll("[cursor-scale='scale-down']");
        scaleDown.forEach(el => {
            el.addEventListener("mouseenter", () => {
                setScale(0.1);
            });
            el.addEventListener("mouseleave", () => {
                setScale(0);
            });
        });
    }, [isOpen]);

    return (
        <motion.div
            ref={cursorEl}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="cursor"
            sx={{
                position: "fixed",
                top: "0px",
                left: "0px",
                zIndex: 100,
                contain: "layout style size",
                pointerEvents: "none",
                variant: "text.body.main",
                "&.media-blend": {
                    zIndex: 100,
                    mixBlendMode: "exclusion",
                    ".cursor-inner": {
                        filter: "invert(1)"
                    }
                }
            }}
        >
            <motion.div
                className="cursor-inner"
                initial={{ scale: 0 }}
                animate={{ scale: scale, background: color }}
                transition={transition}
                sx={{
                    position: "absolute",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                    top: "-24px",
                    left: "-24px",
                    width: "48px",
                    height: "48px",
                    bg: "#dddddd80",
                    backdropFilter: "saturate(180%) blur(5px)",
                    color: "white",
                    fontSize: "9px",
                    borderRadius: "50%"
                }}
            >
                <p className="information"></p>
                <div sx={{ display: "none", color: "white", position: "absolute", zIndex: 1 }}>
                    <ExpandOutline size="1.8rem" />
                </div>
            </motion.div>
        </motion.div>
    );
};

export default Cursor;
