/** @jsx jsx */
import { useState } from "react";
import { Link } from "gatsby";
import { jsx } from "theme-ui";
import { motion, AnimatePresence } from "framer-motion";

import Menu from "./menu";

const Header = ({ isOpen, setIsOpen }) => {
    return (
        <nav
            sx={{
                position: isOpen ? "fixed" : "absolute",
                display: "flex",
                width: "100%",
                top: 0,
                zIndex: 1,
                alignItems: "center",
                px: 2,
                pt: 1
            }}
        >
            <Link
                to="/"
                cursor-scale="scale-down"
                cursor-type="media-blend"
                onClick={() => setIsOpen(false)}
                sx={{
                    textDecoration: "none",
                    fontSize: [3, 4, 4, 4, 5, 5],
                    color: isOpen ? "white" : "black",
                    fontFamily: "heading",
                    lineHeight: "normal",
                    letterSpacing: "normal",
                    zIndex: 11
                }}
            >
                Matt Dunn.
            </Link>
            <div sx={{ mx: "auto" }} />
            <div cursor-type="media-blend" cursor-scale="scale-up">
                <motion.div
                    className="menu-button"
                    onClick={() => setIsOpen(!isOpen)}
                    sx={{
                        position: "relative",
                        cursor: "pointer",
                        fontSize: [3, 4, 4, 4, 5, 5],
                        color: isOpen ? "white" : "black",
                        fontFamily: "heading",
                        lineHeight: "normal",
                        zIndex: 21,
                        letterSpacing: "normal",
                        "::selection": { variant: "selection" }
                    }}
                >
                    {isOpen ? "Close" : "Menu"}
                </motion.div>
            </div>
            <AnimatePresence>
                {isOpen && (
                    <div cursor-type="media-blend">
                        <Menu isOpen={isOpen} />
                    </div>
                )}
            </AnimatePresence>
        </nav>
    );
};

export default Header;
