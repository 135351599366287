/** @jsx jsx */
import { Link } from "gatsby";
import { jsx } from "theme-ui";

const Footer = () => {
    return (
        <footer sx={{ height: "100%", bg: "offWhite", mt: [6, 7, 8] }}>
            <div className="wrapper" sx={{ pt: [3, 4], px: [4, 5, 6, 6, 7, 8] }}>
                <h1
                    className="cta"
                    sx={{
                        variant: "text.title.main",
                        pb: [4, 5],
                        position: "relative",
                        textAlign: ["center", "left"]
                    }}
                >
                    <div className="line">Like what you see?</div>
                    <Link
                        to="/"
                        cursor-scale="scale-down"
                        sx={{
                            textDecoration: "none",
                            color: "black",
                            position: "relative",
                            justifyContent: "center",
                            alignItems: "center",
                            variant: "text.underline"
                        }}
                    >
                        Contact Me
                    </Link>
                </h1>
                <hr sx={{ border: "1px solid", borderColor: "border" }} />
                <div
                    className="footer-info"
                    cursor-type="media-blend"
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: ["column", "row"],
                        alignItems: "center",
                        py: 2,
                        variant: "text.body.alt"
                    }}
                >
                    <p className="copyright" sx={{ fontSize: [2, 2, 2, 3, 4], pb: [1, 0], m: 0 }}>
                        Matt Dunn &copy;2021
                    </p>
                    <div className="site-map">
                        <ul
                            sx={{
                                display: ["none", "none", "none", "flex"],
                                listStyle: "none",
                                m: 0,
                                p: 0,
                                fontSize: [2, 2, 2, 2, 3]
                            }}
                        >
                            <Link
                                to="/projects"
                                cursor-scale="scale-up"
                                sx={{ mx: 1, color: "muted", textDecoration: "none" }}
                            >
                                Projects
                            </Link>
                            <Link
                                to="/about"
                                cursor-scale="scale-up"
                                sx={{ mx: 1, color: "muted", textDecoration: "none" }}
                            >
                                About
                            </Link>
                            <Link
                                to="/skillset"
                                cursor-scale="scale-up"
                                sx={{ mx: 1, color: "muted", textDecoration: "none" }}
                            >
                                Skillset
                            </Link>
                            <Link
                                to="/contact"
                                cursor-scale="scale-up"
                                sx={{ mx: 1, color: "muted", textDecoration: "none" }}
                            >
                                Contact
                            </Link>
                        </ul>
                    </div>
                    <p
                        className="tools"
                        sx={{
                            fontSize: [2, 2, 2, 3, 4],
                            cursor: "pointer",
                            m: 0
                        }}
                    >
                        Tools Used
                    </p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
