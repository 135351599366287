/** @jsx jsx */
import { ReactNode, useEffect } from "react";
import { jsx } from "theme-ui";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

type Props = {
    children: ReactNode;
    name: string;
    shaded?: boolean;
    hero?: boolean;
};

const Section = ({ children, name, shaded, hero }: Props) => {
    const [ref, refInView] = useInView({
        triggerOnce: true,
        rootMargin: "-100px 0px"
    });

    return (
        <motion.section
            className={`${name}-section`}
            ref={ref}
            initial={{ opacity: 0 }}
            animate={{ opacity: refInView ? 1 : 0, transition: { duration: 1 } }}
            sx={{
                position: "relative",
                mt: !hero && [6, 7, 8],
                px: [2, 3, 6, 6, 7, 8],
                py: shaded ? 6 : 0,
                bg: shaded ? "offWhite" : "white"
            }}
        >
            {children}
        </motion.section>
    );
};

export default Section;
