import { format, isFuture } from "date-fns";
import { useState, useEffect } from "react";

export function cn(...args) {
    return args.filter(Boolean).join(" ");
}

export function mapEdgesToNodes(data) {
    if (!data.edges) return [];
    return data.edges.map(edge => edge.node);
}

export function filterOutDocsWithoutSlugs({ slug }) {
    return (slug || {}).current;
}

export function filterOutDocsPublishedInTheFuture({ publishedAt }) {
    return !isFuture(publishedAt);
}

export function getBlogUrl(publishedAt, slug) {
    return `/blog/${format(publishedAt, "YYYY/MM")}/${slug.current || slug}/`;
}

export function buildImageObj(source) {
    const imageObj = {
        asset: { _ref: source.asset._ref || source.asset._id }
    };

    if (source.crop) imageObj.crop = source.crop;
    if (source.hotspot) imageObj.hotspot = source.hotspot;

    return imageObj;
}

// Linear interpolation
export const lerp = (a, b, n) => (1 - n) * a + n * b;

// Gets the mouse position
export const getMousePos = e => {
    let posx = 0;
    let posy = 0;
    if (!e) e = window.event;
    if (e.clientX || e.clientY) {
        posx = e.clientX;
        posy = e.clientY;
    }
    return { x: posx, y: posy };
};
// Get sibilings
export const getSiblings = e => {
    // for collecting siblings
    let siblings = [];
    // if no parent, return no sibling
    if (!e.parentNode) {
        return siblings;
    }
    // first child of the parent node
    let sibling = e.parentNode.firstChild;
    // collecting siblings
    while (sibling) {
        if (sibling.nodeType === 1 && sibling !== e) {
            siblings.push(sibling);
        }
        sibling = sibling.nextSibling;
    }
    return siblings;
};

export function useWindowSize() {
    function getSize() {
        let width;
        let height;
        if (typeof window !== `undefined`) {
            height = window.innerHeight;
            width = window.innerWidth;
        }
        return {
            width: width,
            height: height
        };
    }

    const [windowSize, setWindowSize] = useState(getSize);

    useEffect(() => {
        function handleResize() {
            setWindowSize(getSize());
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowSize;
}

export function getPaddingFromAspectRatio(aspectRatio) {
    return aspectRatio > 1
        ? `min(calc(${aspectRatio * 100}%, 600px)`
        : `${Math.min(aspectRatio * 100, 100)}%`;
}
