/** @jsx jsx */
import { Link } from "gatsby";
import { jsx } from "theme-ui";
import Image from "gatsby-plugin-sanity-image";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useState } from "react";

type BannerProps = {
    fullscreen?: boolean;
    extended?: boolean;
    hero?: boolean;
    isLink?: string;
    button?: React.Component;
};

const transition = { duration: 0.8, ease: [0.43, 0.13, 0.23, 0.96] };

const Figure = ({ listItem, button, isLink, center }) => {
    const [hovered, setHovered] = useState(false);
    const [ref, refInView] = useInView({
        triggerOnce: true,
        threshold: 0.1
    });

    let height = ["500px"];
    if (listItem.size) {
        switch (listItem.size[0]) {
            case "lg":
                height = ["70vw", "60vw", "45vw"];
                break;
            case "md":
                height = ["70vw", "60vw", "35vw"];
                break;
            case "sm":
                height = ["70vw", "60vw", "33.3333vw"];
                break;
            default:
                height = ["600px"];
        }
    }

    return (
        <div>
            {isLink ? (
                <Link
                    cursor-scale="scale-down"
                    onMouseEnter={() => setHovered(true)}
                    onMouseLeave={() => setHovered(false)}
                    to={listItem.slug}
                    className="project"
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        pt: [4, 5],
                        textDecoration: "none"
                    }}
                >
                    <div ref={ref}>
                        <div
                            className="img-container"
                            sx={{
                                position: "relative",
                                width: "100%",
                                height: height,
                                bg: "#efefef",
                                overflow: "hidden"
                            }}
                        >
                            <motion.div
                                className="img-conver"
                                initial={{ width: "100%" }}
                                animate={{ width: refInView ? "0%" : "100%" }}
                                transition={{ ...transition, duration: 1 }}
                                sx={{
                                    position: "absolute",
                                    top: 0,
                                    right: 0,
                                    zIndex: 1,
                                    width: "100%",
                                    height: "100%",
                                    bg: "offWhite"
                                }}
                            ></motion.div>
                            <motion.div
                                initial={{ scale: 1.08 }}
                                animate={{ scale: hovered || !refInView ? 1.08 : 1 }}
                                transition={{ ...transition, duration: 1, delay: -0.4 }}
                                style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "cover"
                                }}
                            >
                                {listItem.asset && (
                                    <Image
                                        {...listItem}
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                            objectFit: "cover"
                                        }}
                                    />
                                )}
                            </motion.div>
                        </div>
                    </div>
                    <div
                        className="project info"
                        sx={{ flexBasis: "100%", mt: listItem.title && [1, 2], overflow: "hidden" }}
                    >
                        <motion.h3
                            initial={{ y: 150, skewY: 15, opacity: 0 }}
                            animate={
                                refInView
                                    ? { y: 0, skewY: 0, opacity: 1 }
                                    : { y: 150, skewY: 15, opacity: 0 }
                            }
                            transition={{ ...transition, delay: 0.5 }}
                            sx={{ variant: "text.heading.sub" }}
                        >
                            {listItem.title}
                        </motion.h3>
                        <motion.p
                            initial={{ y: 150, skewY: 15, opacity: 0 }}
                            animate={
                                refInView
                                    ? { y: 0, skewY: 0, opacity: 1 }
                                    : { y: 150, skewY: 15, opacity: 0 }
                            }
                            transition={{ ...transition, delay: 0.7 }}
                            sx={{ variant: "text.body.alt" }}
                        >
                            {listItem.subtitle}
                        </motion.p>
                        {button && button}
                    </div>
                </Link>
            ) : (
                <div
                    ref={ref}
                    className="project"
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        mt: [1, 2]
                    }}
                >
                    <div
                        className="img-container"
                        sx={{ position: "relative", width: "100%", height: height, bg: "#efefef" }}
                    >
                        <motion.div
                            className="img-conver"
                            initial={{ width: "100%" }}
                            animate={{ width: refInView ? "0%" : "100%" }}
                            transition={{ ...transition, duration: 1 }}
                            sx={{
                                position: "absolute",
                                top: 0,
                                right: 0,
                                zIndex: 1,
                                width: "100%",
                                height: "100%",
                                bg: "white"
                            }}
                        ></motion.div>
                        {listItem.asset && !listItem.banner ? (
                            <Image
                                {...listItem}
                                style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "cover"
                                }}
                            />
                        ) : (
                            <Image
                                {...listItem.banner}
                                style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "cover"
                                }}
                            />
                        )}
                    </div>
                    <div
                        className="project info"
                        sx={{
                            flexBasis: "100%",
                            mt: listItem.title && 1,
                            textAlign: center ? "center" : "start"
                        }}
                    >
                        <h3 sx={{ variant: "text.heading.sub" }}>{listItem.title}</h3>
                        <p sx={{ variant: "text.body.alt" }}>{listItem.subtitle}</p>
                        {button && button}
                    </div>
                </div>
            )}
        </div>
    );
};

export const Banner = ({ fullscreen, hero, imageInfo }) => {
    return (
        <div sx={{ display: "flex", mt: !hero && [6, 7, 8], mx: 0 }}>
            <div
                className="img-container"
                sx={
                    fullscreen
                        ? {
                              width: "100vw",
                              height: ["85vh", "90vh", "100vh"],
                              mx: [-2, -3, -6, -6, -7, -8],
                              bg: "#efefef",
                              position: "relative"
                          }
                        : {
                              width: "100vw",
                              height: "80vh",
                              mx: [-2, -1, -5],
                              bg: "#efefef",
                              position: "relative"
                          }
                }
            >
                {imageInfo && (
                    <Image
                        {...imageInfo}
                        config={{ quality: 80 }}
                        style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover"
                        }}
                    />
                )}
            </div>
        </div>
    );
};

export default Figure;
